import './style.css'
import SiriWave from "siriwave"
import gsap from "gsap"



const oscilloscopeContainer = document.querySelector(".oscilloscope-container")
const oscilloscope = document.querySelector("#oscilloscope")
let top = document.querySelector('li:first-child').offsetTop + document.querySelector('li:first-child').offsetHeight + 4

gsap.set(oscilloscopeContainer, {
    top: top
})
gsap.to(oscilloscopeContainer, {
    autoAlpha: 1
})

let isIn = false,
    isMoving = false,
    tweenWave,
    tweenMove,
    speedBig = .15,
    amplitudeBig = 2,
    speedSmall = .01,
    amplitudeSmall = .5

let siriWave = new SiriWave({
    container: oscilloscope,
    width: 800,
    height: oscilloscope.offsetHeight,
    curveDefinition: [
        { attenuation: 3, lineWidth: 3, opacity: 1 },
    ],
    amplitude: amplitudeSmall,
    speed: speedSmall,
    color: "#E2003C"
})

document.querySelectorAll('li').forEach((item)=>{
    item.addEventListener('mouseenter', (e)=>{
        isMoving = true
        top = item.offsetTop + item.offsetHeight  + 4

        if(tweenMove)
            tweenMove.pause()

        tweenMove = gsap.to(oscilloscopeContainer, {
            top: top,
            duration: 1.2,
            ease: "Power2.easeOut",
            onComplete: ()=>{
                isMoving = false
            }
        })
    })
})

document.querySelector('ul').addEventListener('mouseenter', (e)=>{
    isIn = true

    if(tweenWave)
        tweenWave.pause()

    tweenWave = gsap.to(siriWave, {
        speed: speedBig,
        amplitude: amplitudeBig,
        duration: 1.2,
        ease: "Power2.easeOut",
        onComplete: ()=>{
            if(isIn){
                siriWave.setSpeed(speedBig)
                siriWave.setAmplitude(amplitudeBig)
            }
        }
    })
})
document.querySelector('ul').addEventListener('mouseleave', (e)=>{
    isIn = false
    tweenMove.pause()
    tweenWave.kill()

    siriWave.setSpeed(speedSmall)
    siriWave.setAmplitude(amplitudeSmall)

    top = document.querySelector('li:first-child').offsetTop + document.querySelector('li:first-child').offsetHeight + 4

    gsap.to(oscilloscopeContainer, {
        top: top,
        duration: .6,
        ease: "Power2.easeOut",
    })

})